import React from "react"
import styled from "styled-components"
import { FormattedMessage } from "react-intl"

const StyledForm = styled.form``

const StyledFormGroup = styled.div`
  margin-bottom: 16px;
`

const StyledInput = styled.input`
  width: 100%;
  border-radius: 4px;
  padding: 12px;
  border: 1px solid #ccc;
`

const StyledTextArea = styled.textarea`
  padding: 12px;
  width: 100%;
  resize: vertical;
  border: 1px solid #ccc;
  border-radius: 4px;
`

const StyledButton = styled.button`
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.light100};
  font-weight: 700;
  padding: 16px 80px;
  border-radius: 4px;
  border: none;
  transition: all 0.2s ease;
  box-shadow: 0 12px 25px rgba(206, 50, 50, 0.2);

  :hover {
    background-color: #ad1f1f;
  }

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    width: 100%;
  }
`

const ContactForm = () => {
  return (
    <StyledForm
      name="Contact Form"
      method="POST"
      data-netlify="true"
      action="/contact"
    >
      <input type="hidden" name="form-name" value="Contact Form" />
      <StyledFormGroup>
        <label htmlFor="name">
          <FormattedMessage id="name" />
        </label>
        <StyledInput id="name" type="text" name="name" />
      </StyledFormGroup>
      <StyledFormGroup>
        <label htmlFor="email">
          <FormattedMessage id="email" />
        </label>
        <StyledInput id="email" type="email" name="email" />
      </StyledFormGroup>
      <StyledFormGroup>
        <label htmlFor="message">
          <FormattedMessage id="message" />
        </label>
        <StyledTextArea id="message" rows="10" name="message"></StyledTextArea>
      </StyledFormGroup>
      <StyledButton type="submit">
        <FormattedMessage id="sendMessage" />
      </StyledButton>
    </StyledForm>
  )
}

export default ContactForm
